@import "../../../../styles/mixins";

.wrapper {
  background-color: var(--white-color);
  border-radius: 40px;
  cursor: pointer;

  @include font(Poppins, 0.95em, 400);
  color: var(--deep-black);

  .w {
    width: 5%;
  }
}

.shadow {
  // box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.06);
}

.rounded {
  border-radius: 40px;
}
