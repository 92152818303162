@import "../../../../../../styles/_mixins.scss";

.wrapper {
  margin-bottom: 2em;
  cursor: pointer;
  .innerWrapper {
    border-radius: 16px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.06);
    background-color: var(--white-color);
    //height: 35vh;

    .imgBg {
      background-color: var(--img-bg-color);
    }

    .title {
      @include font(Poppins, 0.9em, 500);
      @media screen and (max-width: 1000px) {
        @include font(Poppins, 0.75em, 400);
      }
    }

    .desc {
      @include font(Poppins, 0.8em, 400);
      opacity: 0.8;
      @media screen and (max-width: 1000px) {
        @include font(Poppins, 0.7em, 400);
      }
    }
  }
}
