@import "../../../styles/mixins";

.wrapper {
  background-color: var(--border-black);

  .name {
    color: var(--black-color);
    @include font(Poppins, 0.9em, 400);
  }
  .number {
    color: var(--deep-black);
    @include font(Poppins, 0.9em, 400);
  }
}
