@import "../../../styles/mixins";

.button {
  background: var(--main-color);
  outline: 0;
  border: 0;
  color: var(--white-color);
  border-radius: 35px;
  transition: background 0.5s;
  @include font(Poppins, 1em);
  &:disabled {
    background-color: var(--black-color);
    opacity: 0.6;
  }
  &:hover {
    background-color: var(--main-color-light);
  }
}

.buttonBordered {
  background: var(--white-color);
  outline: 0;
  border: 1px solid var(--main-color-light);
  color: var(--main-color-light);
  border-radius: 35px;
  transition: background 0.5s;
  @include font(Poppins, 1em);
  &:disabled {
    background-color: var(--black-color);
    opacity: 0.6;
  }
  &:hover {
    background-color: var(--black-color);
  }
}
