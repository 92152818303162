@import "../../../../styles/mixins";

.wrapper {
  margin: 3em 0 1em 0;
  background: var(--white-color);
  border: 1px solid var(--deep-pink);
  border-radius: 40px;
  padding: 0.5em;

  @media screen and (max-width: 900px) {
    margin-top: 2em;
    padding: 0.3em;
  }
}
