@import "../../../styles/mixins";

.wrapper {
  .name {
    color: var(--deep-black);
    @include font(Poppins, 0.9em, 400);

    @media screen and (max-width: 1200px) {
      @include font(Poppins, 0.75em, 400);
    }
  }
  .email {
    color: var(--black-color);
    @include font(Poppins, 0.8em, 400);

    @media screen and (max-width: 1200px) {
      @include font(Poppins, 0.65em, 400);
    }
  }
  .phone {
    @include font(Poppins, 0.8em, 400);

    @media screen and (max-width: 1200px) {
      @include font(Poppins, 0.7em, 400);
    }
  }

  .closeIcon,
  .editIcon {
    transition: 0.4s;
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
}
