@import "../../../styles/mixins";

.textDark {
  color: var(--black-color);
  @include font(Poppins, 0.9em);
}

.textLight {
  color: var(--black-color);
  opacity: 0.4;
  @include font(Poppins, 0.9em);
}
