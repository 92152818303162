@import "../../../styles/mixins";

.wrapper {
  background-color: var(--white-color);
  border: 1px solid var(--border-black);
  outline: 0;
  width: 100%;
  @include font(Poppins, 0.9em, 400);
  padding: 0.7em;

  transition: 0.4s;
  &:hover {
    opacity: 0.7;
  }

  @media screen and (max-width: 1000px) {
    @include font(Poppins, 0.75em, 400);
  }
}
