@import "../../styles/mixins";

.triWrapper {
  right: -21px;
  top: 35px;
}

.wrapper {
  background-color: var(--white-color);
  border-radius: 25px;
  padding: 2em;
  @include boxshadow();

  @media screen and (max-width: 1000px) {
    padding: 1em;
  }

  .icon {
    width: 80%;
    background-color: var(--white-color);
    top: 10;
    cursor: pointer;
    transition: 0.4s;

    &:hover {
      opacity: 0.7;
    }
    @media screen and (max-width: 1000px) {
      width: 85%;
    }
  }

  .w {
    background: black;
  }
}
