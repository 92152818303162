@font-face {
  font-family: "Dunkin-Bold";
  src: url("../../public/fonts/Dunkin-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Dunkin-Bold-Italic";
  src: url("../../public/fonts/Dunkin-Bold-Italic.otf") format("opentype");
}

@font-face {
  font-family: "Dunkin-Sans";
  src: url("../../public/fonts/Dunkin-Sans.otf") format("opentype");
}
