@import "../../../styles/mixins";

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 130px);
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(4, 100px);
  }

  button {
    background-color: transparent;
    border-top: 1px solid var(--black-color-fade);
    border-bottom: 0;
    border-right: 0;
    border-left: 0;
    outline: 0;
    padding: 10px;
    transition: background 0.7s;
    @include font(Poppins, 1.2em, 500);
    &:active {
      background-color: var(--main-color);
    }
  }

  button[data-button-class="item-0"] {
    grid-column: 1/4;
  }

  button[data-button-class="item-ok"] {
    border-radius: 4px;
    grid-column-start: 4;
    grid-row-start: 3;
    grid-row-end: 5;
    padding: 0;
    width: 80px;
    margin-left: 20px;
    margin-top: 5px;
    height: 102px;
    color: var(--white-color);
    background-color: var(--main-color-extra-light);
    @media screen and (max-width: 768px) {
      margin-left: 0px;
      height: 100px;
      width: 80px;
    }
    @media screen and (max-width: 450px) {
      margin-left: 0px;
      height: 80px;
      width: 70px;
    }
  }
}
