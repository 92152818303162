.wrapper {
  bottom: 0;
  height: 91.3vh;
  background-color: rgba(150, 150, 150, 0.8);
  .icon {
    right: 0;
    top: 0;
    width: 46px;
    height: 46px;
    background-color: var(--white-color);
    border-radius: 50%;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.06);
    cursor: pointer;
  }
}
