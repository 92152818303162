@import "../../../styles/mixins";

.wrapper {
  border: 1px solid var(--border-black);
  border-radius: 40px;
  padding: 0.3em 0;

  .amount {
    @include font(Poppins, 2em, 400);
    @media screen and (max-width: 1000px) {
      @include font(Poppins, 1.5em, 400);
    }
  }

  .icon {
    padding: 1em 1.5em;
    border-left: 1px solid var(--border-black);
    @media screen and (max-width: 1000px) {
      padding: 0.5em 1em;
    }
  }
}
