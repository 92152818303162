@import "../../../../styles/mixins";

.wrapper {
  margin: 0.5em 0;
  height: 35vh;
  overflow-y: auto;
  padding: 0 0.8em 0 0;

  @include scrollbars();
}

.summary {
  @include font(Poppins, 1em, 400);
  color: var(--main-color-extra-light);
}
