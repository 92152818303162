@import "../../../../styles/mixins";

.wrapper {
  height: 40vh;
  overflow-y: auto;
  padding: 0 1em 0 0;
  @include scrollbars(3px);

  @media screen and (max-width: 1200px) {
    padding: 0 0.5em 0 0;
  }

  .rotate {
    transition: 0.5s;
    transform: rotate(90deg);
  }

  .transit {
    transition: 0.5s;
  }

  .bodyWrapper {
    margin: 1em 0.5em 1em 1em;

    @include font(Poppins, 0.9em, 400);

    @media screen and (max-width: 1000px) {
      margin: 0.5em 0.2em 0.5em 0.5em;
      @include font(Poppins, 0.75em, 400);
    }

    .tableBodyHeader {
      color: var(--black-color);

      > div {
        flex: 1;
        text-align: left;
      }
    }
    .tableBodyWrapper {
      border: 1px solid var(--border-black);
      margin-top: 0.5em;
      > div {
        padding: 1em;
        flex: 1;
        text-align: center;

        @media screen and (max-width: 1000px) {
          padding: 0.7em;
        }
      }
      > div:nth-child(2) {
        border-left: 1px solid var(--border-black);
        border-right: 1px solid var(--border-black);
      }
      > div:nth-child(1) {
        > input {
          width: 100%;
          border: 0;
          outline: 0;
          text-align: center;
          &:focus {
            border: 0;
            outline: 0;
          }
        }
      }
    }
  }
}
