.wrapper {
  
  padding: 8px 15px;
  border-radius: 15px;
  background-color: var(--white-color);
  outline: 0;
  border: 0;

  &.faded{
    opacity: 0.3;
  }
}
