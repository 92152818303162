@import "../../../styles/mixins";

.wrapper {
  //  filter: drop-shadow(0px 3px 0.2px rgba(0, 0, 0, 0.06));
  .innerWrapper {
    background: var(--white-color);
    height: 50px;
    width: 50px;
    transform: translateY(-50%);
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%, 0% 0%);
  }
}
