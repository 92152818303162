@import "../../../styles/mixins";

.wrapper {
  label {
    @include font(Poppins, 0.8em, 500);
  }

  .toggle {
    border-right: 1px solid var(--inactive-color);
    border-top: 1px solid var(--inactive-color);
    border-bottom: 1px solid var(--inactive-color);
    border-radius: 0 5px 5px 0;
    padding: 12px;
    cursor: pointer;
    @include font(Poppins, 0.9em);

    @media screen and (max-width: 1000px) {
      @include font(Poppins, 0.75em);
    }
  }

  .password {
    width: 99%;
    background: var(--white-color);
    border-radius: 5px 0 0 5px;
    outline: 0;
    border-left: 1px solid var(--inactive-color);
    border-top: 1px solid var(--inactive-color);
    border-bottom: 1px solid var(--inactive-color);
    border-right: none;
    padding: 12px;
    @include font(Poppins, 0.9em);
    &:focus {
      outline: none;
      border-top: 1px solid var(--main-color-light);
      border-left: 1px solid var(--main-color-light);
      border-bottom: 1px solid var(--main-color-light);
    }
    &:focus + .toggle {
      outline: none;
      border-top: 1px solid var(--main-color-light);
      border-right: 1px solid var(--main-color-light);
      border-bottom: 1px solid var(--main-color-light);
    }
    &::placeholder {
      color: var(--black-color);
      opacity: 0.7;
    }
    @media screen and (max-width: 1000px) {
      @include font(Poppins, 0.75em);
    }
  }

  .inputBox {
    width: 100%;
    background: var(--white-color);
    border-radius: 5px;
    border: 1px solid var(--inactive-color);
    padding: 12px;
    @include font(Poppins, 0.9em);
    &:focus {
      outline: none;
      border: 1px solid var(--main-color-light);
    }

    @media screen and (max-width: 1000px) {
      @include font(Poppins, 0.75em);
    }
    &::placeholder {
      color: var(--black-color);
      opacity: 0.7;
    }

    &.error {
      border: 1px solid var(--error);
    }
  }
}
