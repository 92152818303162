@import "../../../../styles/mixins";

// .wrapper {
//   background-color: var(--white-color);
//   border-radius: 40px;
//   cursor: pointer;

//   @include font(Poppins, 0.95em, 400);
//   color: var(--deep-black);

//   .w {
//     width: 5%;
//   }
// }

.wrapper1 {
  background-color: var(--white-color);
  height: 400px;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.06);

  .wrapper {
    background-color: var(--white-color);
    color: black;
    height: 400px;
    overflow-y: auto;
    @include scrollbars(3px);

    @media screen and (max-width: 1000px) {
      @include font(Poppins, 0.9em, 400);
    }
    .active {
      background-color: var(--deep-pink) !important;
      color: var(--white-color) !important;
      border-radius: 40px;
    }
    .list {
      border-bottom: 1px solid var(--deep-pink);
      cursor: pointer;
      transition: 0.4s;

      &:hover {
        background-color: rgba(124, 124, 124, 0.486);
        border-radius: 40px;
        .quantity {
          @include font(Poppins, 0.85em, 400);

          color: var(--white-color);
        }
      }

      .name {
        @include font(Poppins, 0.85em, 400);
        color: var(--deep-black);
        text-transform: capitalize;
      }
      .quantity {
        @include font(Poppins, 0.85em, 300);
        color: var(--color-ad);
      }
    }
  }
}
