@import "../../../styles/mixins";

.wrapper {
  transition: 1s;
  height: auto;
  background-color: var(--white-color);
  box-sizing: border-box;
  padding: 0.2em 0;

  .header {
    border: 1px solid var(--border-black);
    outline: 0;
    background-color: transparent;
    width: 100%;
    cursor: pointer;
    @include font(Poppins, 0.9em, 400);
    padding: 0.6em;
    @media screen and (max-width: 1000px) {
      @include font(Poppins, 0.75em, 400);
    }
  }
}
