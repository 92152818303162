@import "../../../../styles/mixins";

.text {
  width: 25%;
  @include font(Poppins, 1em, 400);
  color: var(--grey);

  @media screen and (max-width: 1000px) {
    width: 100%;
    @include font(Poppins, 0.8em, 400);
  }
  @media screen and (max-width: 1200px) {
    width: 50%;
    @include font(Poppins, 0.8em, 400);
  }
  @media screen and (max-width: 1400px) {
    width: 50%;
    @include font(Poppins, 0.8em, 400);
  }
}
