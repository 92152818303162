@import "../../../styles/mixins";

.wrapper {
  .label {
    color: var(--dark-color);
    @include font(Poppins, 0.9em, 400);
    margin: 0.4em 0;
  }
  > textarea {
    width: 100%;
    resize: none;
    border: 1px solid var(--inactive-color);
    padding: 1em;
    border-radius: 5px;

    @include font(Poppins, 0.9em);
    &:focus {
      outline: none;
      border: 1px solid var(--main-color-light);
    }

    &::placeholder {
      color: var(--black-color);
      opacity: 0.7;
    }

    @include scrollbars();
  }
}
