@import "../../../styles/mixins";

@mixin fontSmallScreen {
  @media screen and (max-width: 1000px) {
    @include font(Poppins, 0.75em, 400);
  }
}

.wrapper {
  margin-bottom: 1em;
  margin-top: 0.5em;
  .items {
    border-top: 1px solid var(--border-black);
    padding: 0.5em;
    @include font(Poppins, 0.9em, 400);

    @include fontSmallScreen();
  }
  .items:nth-child(1),
  .items:nth-child(2) {
    > div:nth-child(2) {
      @include font(Poppins, 0.87em, 400);
    }
  }
  .items:nth-child(3) {
    > div:nth-child(1) {
      @include font(Poppins, 0.9em, 600);
      > span {
        @include font(Poppins, 0.9em, 400);
        color: var(--black-color);
      }
    }
    > div:nth-child(2) {
      @include font(Poppins, 1.2em, 600);
    }
  }
}
