@import "../../../styles/mixins";

.marginRight {
  margin-right: 3rem;

  @media screen and (max-width: 1000px) {
    margin-right: 1rem;
  }
}

.imgBg {
  border-radius: 50%;
  width: 76px;
  height: 76px;
  background-color: var(--light-pink);
}

.text1 {
  @include font(Poppins, 2em, 300);
  color: var(--deep-black);
  @media screen and (max-width: 768px) {
    @include font(Poppins, 1.2em, 500);
  }
}

.text2 {
  @include font(Poppins, 0.9em, 400);
  color: var(--deep-black);
  opacity: 0.6;
  @media screen and (max-width: 1000px) {
    @include font(Poppins, 0.7em, 400);
  }
}

.text3 {
  @include font(Poppins, 0.9em, 400);
  color: var(--main-color-extra-light);
  font-style: italic;
  @media screen and (max-width: 768px) {
    @include font(Poppins, 0.7em, 400);
  }
}
