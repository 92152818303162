:root {
  --main-color: #a63494;
  --inactive-color: #e6e6e6;
  --white-color: #ffffff;
  --main-color-light: #7f34a6;
  --black-color: #a7a7a7;
  --black-color-fade: #fbf2ff;
  --main-color-extra-light: #7f5297;
  --light-pink: #fbf2ff;
  --deep-black: #353535;
  --deep-pink: #eddcf6;
  --deeper-pink: #d3a8e9;
  --lighter-pink: #f6edfa;
  --faded-white: #fdfbfe;
  --main-color-dime: #a569c5;
  --img-bg-color: #fefbff;
  --grey: #c4c4c4;
  --color-ad: #adadad;
  --border-black: #ebebeb;
  --green: #51d4a7;
  --error: #c70039;
}

[data-theme="dark"] {
  --main-color: #f0f0f0;
  --text-color-secondary: #b3c5cd;

  --primary-color: #21b6b7;

  --background-color-dark: #252329;
  --background-color: #3c393f;
  --background-color-light: #120f13;
}

[data-theme="light"] {
  --main-color: black;
  --text-color-secondary: #b3c5cd;

  --primary-color: #21b6b7;

  --background-color-dark: #252329;
  --background-color: #3c393f;
  --background-color-light: #120f13;
}
