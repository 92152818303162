@import "../../../styles/mixins";

.button {
  outline: 0;
  border: 0;
  background-color: transparent;
  color: var(--main-color-extra-light);
  @include font(Poppins, 0.9em, 400);
  transition: 0.3s;

  &:disabled {
    opacity: 0.3;
  }
  &:hover {
    opacity: 0.7;
  }

  @media screen and (max-width: 1000px) {
    @include font(Poppins, 0.75em, 400);
  }
  //@media screen and (max-width: 900px) {
  //  @include font(Poppins, 0.75em, 400);
  //}
}
