@import "../../../../styles/_mixins.scss";

.wrapper {
  height: 52vh;
  overflow-y: auto;

  @include scrollbars;

  .container {
    padding: 0 2rem 0 0;
    @media screen and (max-width: 1000px) {
      padding: 0 1rem 0 0;
    }
  }
}
