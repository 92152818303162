@import "../../../styles/mixins";

.inputEnter {
  outline: 0;
  padding: 5px 20px;
  text-align: center;
  width: 72.28px;
  height: 72.28px;
  border: 1px solid var(--inactive-color);
  border-radius: 4px;
  color: var(--main-color);
  @include font(Poppins, 1.7em, 500);
  transition: border 1s;
  @media screen and (max-width: 450px) {
    width: 55px;
    height: 55px;
    padding: 5px 10px;
  }

  &:focus {
    border: 1px solid var(--main-color);
  }
  &::placeholder {
    color: var(--black-color);
    opacity: 0.8;
  }
}
