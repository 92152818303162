@import "../../../styles/mixins";

.wrapper {
  padding: 1em;

  background-color: var(--white-color);
  color: var(--deep-color);
  @include boxshadow();

  @media screen and (max-width: 1000px) {
    //padding: 0.5em 0.5em;
  }

  .title {
    background-color: var(--white-color);
    border-bottom: 1px solid var(--border-black);
    left: 0;
    @include font(Poppins, 0.85em, 400);
  }
  .innerWrapper {
    height: 50vh;
    overflow-y: auto;
    margin-top: 2.8em;

    @include scrollbars(4px, #e2e2e2, #a7a7a7);

    .body {
      .item {
        @include font(Poppins, 0.8em, 400);
        padding: 0.8em 0.3em;
        margin-right: 0.4em;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
          border-radius: 10px;
        }
        &.colored {
          background-color: rgba(127, 82, 151, 0.2) !important;
          color: var(--main-color) !important;
          border-radius: 10px;
        }
        .circle {
          margin-right: 1em;
          width: 8px;
          height: 8px;
          border: 1px solid var(--black-color);
          border-radius: 50%;
        }
        .dot {
          margin-right: 1em;
          width: 10px;
          height: 10px;
          border: 1px solid var(--black-color);
          border-radius: 50%;
          > div {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--main-color);
          }
        }
      }
    }
  }
}
