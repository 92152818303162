@import "../../../../styles/mixins";

.wrapper {
  margin: 3em;
  background-color: var(--white-color);
  border-radius: 20px;
  padding: 1em 2em;
  overflow-y: auto;
  @include boxshadow();

  .title {
    margin-bottom: 3em;
    color: var(--main-color-extra-light);
    @include font(Poppins, 1em, 500);

    @media screen and (max-width: 800px) {
      margin-bottom: 1em;
    }
  }

  .cursorDisabled {
    cursor: not-allowed;
  }
}
