@import "../../../../../../styles/_mixins.scss";

.wrapper {
  margin-bottom: 2em;
  cursor: pointer;

  .innerWrapper {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.06);
    background-color: var(--white-color);

    .imgBg {
      background-color: var(--img-bg-color);
    }

    .flex1 {
      flex: 7;
    }

    .flex2 {
      flex: 3;
    }

    .title,
    .price {
      color: var(--dark-color);
      @include font(Poppins, 0.85em, 400);
      @media screen and (max-width: 1000px) {
        @include font(Poppins, 0.75em, 400);
      }
    }
  }
}
