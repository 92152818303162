@import "../../../styles/mixins";

.innerWrapper {
  padding: 0 20px 0 0;
  height: 65vh;
  margin-top: 45px;
  overflow-y: auto;
  @include scrollbars(4px, #e2e2e2, #a7a7a7);

  .nameIndex {
    color: var(--deep-color);
    @include font(Poppins, 2.5em, 400);
  }

  .valueIndex {
    border-top: 1px solid var(--border-black);
    border-bottom: 1px solid var(--border-black);
    padding: 1em 0;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    @include font(Poppins, 0.9em, 400);

    &:hover {
      opacity: 0.7;
    }

    @media screen and (max-width: 1000px) {
      @include font(Poppins, 0.75em, 400);
    }
  }
}
