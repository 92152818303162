@import "../../../styles/mixins";

.wrapper {
  background-color: var(--white-color);
  padding: 4em 3em 0.5em 3em;
  border-left: 1px solid var(--border-black);
  @media screen and (max-width: 1200px) {
    padding: 2em 1.5em 0.5em 1em;
  }
  .title {
    color: var(--main-color-dime);
    @include font(Poppins, 0.8em, 400);
  }
}
