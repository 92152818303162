@import "../../../../styles/mixins";

.pay {
  color: var(--main-color-extra-light);
  @include font(Poppins, 2em, 400);

  @media screen and (max-width: 100px) {
    @include font(Poppins, 1.2em, 400);
  }
}
