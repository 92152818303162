@import "../../../styles/mixins";

.wrapper {
  color: var(--deep-black);

  .btn {
    color: var(--deep-black);
    width: 40%;
    border-radius: 90px;
    background-color: var(--faded-white);
    @include font(Poppins, 1.2em, 400);

    .value {
      > span {
        @include font(Arial, 0.8em, 400);
      }
      @include font(Poppins, 1.5em, 400);
    }
  }

  .tCompleted {
    @include font(Poppins, 1.2em, 400);
  }

  .tID {
    @include font(Poppins, 0.85em, 400);
    color: var(--black-color);
  }

  .nextSale {
    cursor: pointer;
    @include font(Poppins, 2em, 400);
    color: var(--green);
    transition: 0.3s;
    &:hover {
      opacity: 0.6;
    }
  }
}
