@import "../../../styles/mixins";

.wrapper {
  background-color: var(--faded-white);
  padding: 0 3em 0.5em 3em;

  @media screen and (max-width: 1200px) {
    padding: 0 1.2em 0.5em 1em;
  }
  .title {
    color: var(--main-color-dime);
    @include font(Poppins, 0.8em, 400);
  }
}
