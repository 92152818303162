@import "../../../styles/mixins";

.wrapper {
  height: 40vh;
  padding: 0 3em;
  overflow-y: auto;
  margin-bottom: 1em;

  @include scrollbars();

  @include font(Poppins, 1.2em, 400);

  @media screen and (max-width: 1000px) {
    @include font(Poppins, 1em, 400);
    padding: 0 1.5em;
  }

  @media screen and (max-width: 1000px) {
    @include font(Poppins, 0.9em, 400);

    padding: 0 1.5em;
  }

  .innerWrapper {
    &:first-child {
      border-top: 1px solid var(--border-black);
      padding: 1.2em 1em;
    }
    &:not(first-child) {
      margin: 0.2em 0;
    }
    cursor: pointer;
    transition: 0.5s;
    padding: 1em;
    border-bottom: 1px solid var(--border-black);

    &:hover {
      opacity: 0.7;
    }
  }

  .reason {
    color: var(--black-color);
    @include font(Poppins, 0.7em, 400);
  }
}
