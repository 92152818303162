@import "../../../../../styles/mixins";

.btnMargin {
  margin-left: 1em;
}

.btnPadding {
  padding: 0.9em;
}

.textArea {
  height: 103px;
}

.balance {
  color: var(--deep-color);
  @include font(Poppins, 0.8em, 500);
}
