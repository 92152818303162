@mixin font($font, $size, $weight: 400) {
  font: {
    family: $font;
    size: $size;
    weight: $weight;
  }
}

@mixin scrollbars(
  $size: 3px,
  $foreground-color: #e2e2e2,
  $background-color: var(--deep-black)
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: $background-color;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: $foreground-color;
    border-radius: 5px;
  }
  scrollbar-color: $foreground-color $background-color;
}

@mixin boxshadow {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.06);
}

@mixin nairaIcon($weight: 0.8em) {
  @include font(Arial, $weight, 400);
}
