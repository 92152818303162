@import "../../../styles/mixins";

.back {
  @include font(Poppins, 1.1em, 300);
  color: var(--main-color);
  margin-right: 40px;
  left: 20%;
  cursor: pointer;

  @media screen and (max-width: 786px) {
    left: 8%;
  }
}
