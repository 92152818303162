@mixin font($font, $size, $weight: 400) {
  font: {
    family: $font;
    size: $size;
    weight: $weight;
  }
}

.wrapper {
  left: 0;
  top: 0;
  height: 100vh;
  background-color: var(--lighter-pink);
  padding-left: 5px;
  z-index: 99;
  .text {
    color: var(--main-color-extra-light);
    @include font(Poppins, 0.6em, 500);
  }

  .cursor {
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background-color: var(--black-color);
      border-radius: 40px 0px 0px 40px;
      opacity: 0.85;
    }
  }

  .bgHighlight {
    background-color: var(--faded-white);
    border-radius: 40px 0px 0px 40px;
  }

  .close {
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: 0.4s;
    &:hover {
      background-color: var(--white-color);
      border-radius: 50%;
    }
  }
}
