@import "../../../../../styles/mixins";

.title {
  color: var(--error);
  @include font(Poppins, 1.5em, 400);
}

.itemName {
  @include font(Poppins, 1em, 400);
  color: var(--black-color);
}
