@import "../../../styles/mixins";

.wrapper {
  background-color: var(--faded-white);
  padding: 3em 1.2em 0.5em 4em;

  @media screen and (max-width: 900px) {
    padding: 1.8em 1.2em 0.5em 2em;
  }
  .title {
    color: var(--main-color-dime);
    @include font(Poppins, 0.8em, 400);
  }
}
