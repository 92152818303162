@mixin font($font, $size, $weight: 400) {
  font: {
    family: $font;
    size: $size;
    weight: $weight;
  }
}

.wrapper {
  border-radius: 40px;
  background-color: white;

  input {
    background-color: transparent;
    border: 0;
    outline: 0;
    &::placeholder {
      @include font(Poppins, 0.8em, 300);
      color: var(--black-color);
    }
  }
}
