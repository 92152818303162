@import "../../../../../styles/mixins";

.title {
  color: var(--error);
  @include font(Poppins, 1.5em, 400);
}
.wrapper {
  .textArea {
    height: 153px;
  }
}
