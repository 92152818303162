@import "../../../../styles/mixins";

.wrapper {
  > div:nth-child(1) {
    > button {
      outline: 0;
      border: 0;
      padding: 0.8em 1.5em;
      border-radius: 5px;
      color: var(--white-color);
      @include font(Poppins, 0.6em, 400);

      &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
      }
      @media screen and (max-width: 1000px) {
        @include font(Poppins, 0.5em, 500);
      }
    }
    > button:nth-child(1) {
      background-color: rgb(233, 207, 229);
      &:hover {
        background-color: rgba(233, 207, 229, 0.7);
      }
    }
    > button:nth-child(2) {
      margin: 0 0.5em;
      background-color: rgb(233, 225, 209);
      &:hover {
        background-color: rgba(233, 225, 209, 0.7);
      }
    }
    > button:nth-child(3) {
      background-color: rgb(226, 211, 216);
      &:hover {
        background-color: rgba(226, 211, 216, 0.7);
      }
    }
  }

  > div:nth-child(2) {
    button {
      outline: 0;
      border: 0;
      background-color: var(--green);
      border-radius: 5px;
      color: var(--white-color);
      @include font(Poppins, 1.5em, 500);
      transition: 0.4s;
      &:hover {
        opacity: 0.7;
      }

      @media screen and (max-width: 1000px) {
        @include font(Poppins, 1.1em, 500);
      }
    }
  }
}
