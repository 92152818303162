@import "../../styles/mixins";

.wrapper {
  background: var(--main-color-extra-light);

  .menu {
    cursor: pointer;
  }

  .icon {
    cursor: pointer;
    @include font(Dunkin-Sans, 1.2em, 100);
    color: var(--white-color);
  }

  .weatherText {
    color: var(--deep-pink);
    @include font(Poppins, 0.7em, 500);
  }

  .grWrapper {
    text-align: right;
  }

  .cursor {
    cursor: pointer;
  }

  .position {
    color: var(--deeper-pink);
    @include font(Poppins, 0.75em, 400);
  }
  .greeting {
    color: var(--white-color);
    @include font(Poppins, 0.8em, 400);
  }
}
