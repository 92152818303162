@import "../../../../styles/mixins";

.wrapper {
  margin: 3em 0 1em 0;
  background: var(--white-color);
  border: 1px solid var(--deep-pink);
  border-radius: 40px;
  padding: 0.5em;

  @media screen and (max-width: 900px) {
    margin-top: 2em;
    padding: 0.3em;
  }

  > input {
    border: 0;
    outline: 0;
    width: 100%;
    margin: 0 0.5rem;
    &::placeholder {
      color: var(--inactive-color);
      @include font(Poppins, 1em, 400);
    }
  }

  > button {
    outline: 0;
    border: 0;
    background-color: transparent;
    transition: 0.4s;
    &:hover {
      opacity: 0.6;
    }
  }
}
